import { hasData } from "@/lib/utils/hasData";
import toast from "react-simple-toasts";

//LIKES
export const likeHandlerComment = async (
  post_comment_id,
  chatter_id,
  my_id,
  host_id
) => {
  if (hasData(post_comment_id)) {
    const res = await fetch(
      `/api/private/post_comment/like?id=${post_comment_id}&host=${host_id}`
    );
    const json = await res.json();
    if (json) {
      const { action } = json[0][0];

      //WEBSOCKET
      if (action?.toString() == "429") {
        toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
          time: 3000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
      }

      return action;
    }
  }

  return -3;
};

//DELETES
export const removeHandlerComment = async (
  comment_id,
  host_id,
  set_cache,
  isConfirmed,
  set_comments
) => {
  const confirmed = await confirm(
    `Are you sure you want to delete this message?`
  );
  //const confirmed = await isConfirmed(`Are you sure you want to delete this message?`);
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(comment_id)) {
      const res = await fetch(
        `/api/private/post_comment/delete?id=${comment_id}&host=${host_id}`
      );
      const json = await res.json();
      if (json) {
        const { action, comments } = json[0][0];

        if (action?.toString() == "429") {
          toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else if (action?.toString() == "1") {
          set_comments((prev) => prev - 1);
          toast(`Comment has been deleted.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
        set_cache(+new Date());
      }
    }
  }
};

//REPORTS
export const reportHandlerComment = async (
  comment_id,
  my_id,
  host_id,
  manual_action
) => {
  if (hasData(comment_id)) {
    const res = await fetch(
      `/api/private/post_comment/report?id=${comment_id}&action=${manual_action}&host=${host_id}`
    );
    const json = await res.json();
    if (json) {
      //console.log("json",json)
      const { action } = json[0][0];
      //console.log(action)
      if (action?.toString() == "429") {
        toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, {
          time: 3000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
      } else if (action >= 1) {
        toast(`Report submitted`, {
          time: 3000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
        //mutateItemsDeleteOne(comment_id)
      } else {
        toast(`Reports removed.`, {
          time: 3000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
        //mutateItemsDeleteOne(comment_id)
      }
    }
  }
};
