import toast from "react-simple-toasts";
import { hasData } from "../hasData";

//IGNORE
export const ignoreHandler = async (
  target_id,
  action,
  target_name,
  authContext,
  isConfirmed
) => {
  const confirmed = await confirm(
    `Are you sure you want to ${
      action == "1" ? `add ${target_name} to` : `remove ${target_name} from`
    } your private ignore list?`
  );
  //const confirmed = await isConfirmed(`Are you sure you want to ${action=="1" ? "ignore" : "un-ignore"} ${target_name}?`);
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(target_id)) {
      const res = await fetch(
        `/api/private/user-ignore/insert?target_id=${target_id}&action=${action}`
      );
      const json = await res.json();
      if (json) {
        const { outcome, ignore_json } = json[0][0];

        await authContext.set_userContextValue({
          ...authContext,
          ignore_json: ignore_json,
        });

        if (outcome?.toString() == "1") {
          toast(
            `${target_name} has been ${
              action == "1" ? "added to" : "removed from"
            } your private ignore list. You can manage ignored users in your personal settings.`,
            { time: 3000, className: "", clickable: true, clickClosable: false }
          );
        } else {
          toast(`Something went wrong. Please try again.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};
