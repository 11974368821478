import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import PostStateBreakdown from "./post-state-breakdown";
import { useSWRInfinite } from "swr";
import InfiniteScroll from "react-swr-infinite-scroll";
import LoaderToggle from "@/components/templateux/loading/loader-toggle";
import { textProcess } from "@/lib/utils/textprocess";
import useTimestamp from "@/lib/utils/hooks/useTimestamp";
import isAdminUser, { isAdminObject } from "@/lib/utils/isAdminUser";

const fetcher = (url) => fetch(url).then((res) => res.json());
const PAGE_SIZE = 6;

export default function PostArray({
  itemdata,
  placeholderdata = [],
  isLoading = false,
  isError = false,
  userdata,
  ownerdata,
  display_view,
  set_display_view,
  cache,
  set_cache,
  slugfilter,
  nodata,
  navtype,
  helptype,
  slug,
  title,
  swr_url = `/api/private/post/select?option=user_id&id=${ownerdata?.id}`,
  ssr_data_tribe,
  origin_url,
  origin_title,
  ssr_isadmin,
}) {
  const [itemdata_active, set_itemdata_active] = useState(itemdata);
  const [data_loaded, set_data_loaded] = useState(false);

  //swr infinite stuff
  const swr = useSWRInfinite(
    (index) => `${swr_url}&page=${index + 1}`,
    fetcher
  );

  const { data, error, mutate, size, setSize, isValidating } = swr;

  const posts = data ? [].concat(...data) : itemdata_active;
  const isLoadingInitialData = !posts;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty =
    !data || (data && !data[0]) || (data && data[0] && data[0]?.length === 0);
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE);
  const isRefreshing =
    isValidating && data && data[0] && data[0].length === size;

  const router = useRouter();
  const { descriptionRef } = useTimestamp(posts);

  useEffect(() => {
    if (!data_loaded && data) {
      //set_itemdata_active(data);
      set_data_loaded(true);
    }
  }, [data_loaded, data]);

  if (isError) {
    return <div className="px-4">Something went wrong...</div>;
  }

  if (
    !isLoading &&
    !isError &&
    (!posts ||
      (posts &&
        (display_view == "audio"
          ? posts.filter(
              (e) =>
                ["2", "3", "5", "13"].indexOf(e?.post_type?.toString()) > -1
            ) //filter out audio
          : posts
        )?.length == 0))
  ) {
    return (
      <>
        <div className="w-full px-4 py-5 mt-3 text-md text-center text-white font-bold">
          {nodata}
        </div>
      </>
    );
  }

  return (
    <>
      {!isLoading && !isError && (!posts || (posts && posts?.length > 0)) && (
        <div className="p-2 bg-gray-900 sm:pt-4">
          {/* {isEmpty ? <p>{nodata}</p> : null} */}

          <InfiniteScroll
            swr={swr}
            loadingIndicator={<></>}
            endingIndicator={
              <div className="text-center my-5 italic">
                <div>{nodata}</div>
                <div className="flex justify-center my-4">
                  <div
                    onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }}
                    className="cursor-pointer bg-blue-600 text-white font-bold px-8 py-3  rounded-full"
                  >
                    {textProcess("Back to Top")}
                  </div>
                </div>
              </div>
            }
            // isReachingEnd={(swr) =>
            //   swr[0]?.length === 0 || swr[swr?.length - 1]?.length < PAGE_SIZE
            // }
            isReachingEnd={isReachingEnd}
          >
            {posts?.map((e, index) => (
              <div key={index}>
                <PostStateBreakdown
                  headline={textProcess("All Posts")}
                  itemdata={isLoading ? placeholderdata : e}
                  itemdataisLoading={isLoading}
                  itemdataisError={isError}
                  userdata={userdata}
                  ownerdata={ownerdata}
                  ssr_data_tribe={ssr_data_tribe}
                  display_view={display_view}
                  cache={cache}
                  set_cache={set_cache}
                  nodata={nodata}
                  navtype={navtype}
                  helptype={helptype}
                  slug={slug}
                  router={router}
                  descriptionRef={descriptionRef}
                  index={e?.post_id}
                  isExpanded={false}
                  origin_url={origin_url}
                  origin_title={origin_title}
                  ssr_isadmin={ssr_isadmin}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}

      {!isReachingEnd && (
        <div className="flex justify-center my-4">
          <div
            onClick={() => {
              if (!isLoadingMore && !isReachingEnd) setSize(size + 1);
            }}
            className="cursor-pointer border border-gray-600 text-white font-bold px-8 py-3  rounded-full"
          >
            <LoaderToggle
              loadingstate={isLoadingMore}
              actionText={textProcess("load more")}
              actionTextShort={textProcess("load more")}
              loadingClass="animate-pulse text-gray-400 w-5 h-5"
              toggle={{
                bgColor: "transparent",
                centerColor: "transparent",
                holeColor: "transparent",
                pointerColor: "#ffffff",
                dialerColor: "#ffffff77",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
