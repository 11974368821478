import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { config, playbackRate_array } from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp";
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { timerFormat } from "@/lib/utils/timerFormat";
import {
  Fragment,
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactSlider from "react-slider";
import Image from "next/legacy/image";
import ReactTooltip from "react-tooltip";
import ReactPlayer from "react-player/file";
import { isNumeric } from "@/lib/utils/isNumeric";
import LoaderToggle from "@/components/templateux/loading/loader-toggle";
import toast from "react-simple-toasts";
import { timerStr } from "@/lib/utils/timerStr";
import { hasData } from "@/lib/utils/hasData";
import { srcSafe } from "@/lib/utils/srcSafe";

const decimalval = 1000;
const max_zoom = 200;
const min_zoom_interval = 1100; // in milliseconds

const isRangeSafe = (val, min, max) => {
  if (val >= min && val <= max) return true;
  return false;
};

const getRatioFromSeconds = (seconds, duration, key = false) => {
  var topval = ((seconds * 100) / duration) * decimalval;
  if (!key) topval = Math.floor(topval);
  let val = topval / decimalval;
  //console.log("getRatioFromSeconds",val,seconds,duration)
  return val;
};

const getSecondsFromRatio = (x, duration) => {
  let val = Math.floor((x / 100) * duration * decimalval) / decimalval;

  return val;
};

export default function PostClipEdit({
  min = 10,
  max = 90,
  itemdata,
  duration,
  audio_url,
  waveform_url = `/images/app/waveform-placeholder.png`,
  clip_state,
  set_clip_state,
  set_clip_json,
  changes,
  set_changes,
}) {
  // console.log("audio_url",audio_url)

  const scrollRef = useRef(null);
  const thumbStartRef = useRef(null);
  const thumbTrackRef = useRef(null);
  const thumbEndRef = useRef(null);
  const clipplayerRef = useRef(null);
  const waveformRef = useRef(null);
  const [clipplayer_state, set_clipplayer_state] = useState({
    isPlaying: false,
    isLoaded: false,
    isMuted: false,
    isSeeking: false,
    isLoading: false,
    isBuffering: false,
    isEnded: false,
    seek: 0,
    seek_slider: 0,
    isMediaLoaded: false,
    playbackRate: 1,
    volume: 0.8,
  });

  const [edit_input, set_edit_input] = useState(-1);
  const [isMounted, set_isMounted] = useState(false);
  const [zoom, set_zoom] = useState(0);
  const [zoom_last, set_zoom_last] = useState(0);
  const [volume_slider, set_volume_slider] = useState(80);
  const [cache, set_cache] = useState(null);

  const [submitting, set_submitting] = useState(false);
  const [default_cap_time, set_default_cap_time] = useState(
    clip_state?.auto == 0
      ? [
          clip_state?.start >= 0 ? clip_state?.start : 0,
          clip_state?.track,
          clip_state?.end <= duration ? clip_state?.end : duration,
        ]
      : [
          getSecondsFromRatio(min, duration),
          getSecondsFromRatio(min, duration),
          getSecondsFromRatio(max, duration),
        ]
  );
  const [cap_slider, set_cap_slider] = useState(
    clip_state?.auto == 0
      ? [
          getRatioFromSeconds(
            clip_state?.start >= 0 ? clip_state?.start : 0,
            duration
          ),
          getRatioFromSeconds(clip_state?.track, duration),
          getRatioFromSeconds(
            clip_state?.end <= duration ? clip_state?.end : duration,
            duration
          ),
        ]
      : [min, min, max]
  );
  const [cap_time, set_cap_time] = useState(default_cap_time);
  const [last_cap_time, set_last_cap_time] = useState(default_cap_time);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  useEffect(() => {
    if (!hasData(duration) || duration == 0) {
      //generate track caps
      let default_arr =
        clip_state?.auto == 0
          ? [
              clip_state?.start >= 0 ? clip_state?.start : 0,
              clip_state?.track,
              clip_state?.end <= duration ? clip_state?.end : duration,
            ]
          : [
              getSecondsFromRatio(min, duration),
              getSecondsFromRatio(min, duration),
              getSecondsFromRatio(max, duration),
            ];

      set_default_cap_time(default_arr);
      set_cap_time(default_arr);
      set_last_cap_time(default_arr);

      set_cap_slider(
        clip_state?.auto == 0
          ? [
              getRatioFromSeconds(
                clip_state?.start >= 0 ? clip_state?.start : 0,
                duration
              ),
              getRatioFromSeconds(clip_state?.track, duration),
              getRatioFromSeconds(
                clip_state?.end <= duration ? clip_state?.end : duration,
                duration
              ),
            ]
          : [min, min, max]
      );
    }
  }, [duration, clip_state]);

  const zoom_level = (level, duration) => {
    let ticks = Math.floor(
      scrollRef?.current?.getBoundingClientRect().width / 75
    );

    let containerwidth = scrollRef?.current?.getBoundingClientRect().width;
    //let wavewidth = waveformRef?.current?.getBoundingClientRect().width;
    let wavewidth = scrollRef?.current?.scrollWidth;

    let extra_ratio = wavewidth / containerwidth;
    let total_ticks = Math.floor(ticks * extra_ratio);

    if (level == 0) return { width: "100%", ticks: ticks };
    return { width: `${100 + level * 100}%`, ticks: total_ticks };
  };

  const applyCap = (thisval, defaultval) => {
    var val = thisval;

    if (!isNumeric(val)) val = defaultval;

    var seconds = parseFloat(val?.toString());
    if (seconds < 0) seconds = 0;
    if (seconds > duration) seconds = duration;
    let ratio = getRatioFromSeconds(val, duration);

    //update playhead if this is a track
    if (edit_input == 1) clipplayerRef?.current?.seekTo(seconds, "seconds");

    set_cap_time((prev) => {
      return (
        {
          0: [
            seconds,
            prev[1], // >= seconds ? prev[1] : seconds,
            prev[2], // >= seconds ? prev[2] : seconds
          ],
          1: [
            prev[0], // >= seconds ? prev[0] : seconds,
            seconds,
            prev[2], // >= seconds ? prev[2] : seconds
          ],
          2: [
            prev[0], // >= seconds ? prev[0] : seconds,
            prev[1], // >= seconds ? prev[1] : seconds,
            seconds,
          ],
        }[edit_input] || prev
      );
    });
    set_cap_slider((prev) => {
      return (
        {
          0: [
            ratio,
            prev[1], // >= ratio ? prev[1] : ratio,
            prev[2], // >= ratio ? prev[2] : ratio
          ],
          1: [
            prev[0], // >= ratio ? prev[0] : ratio,
            ratio,
            prev[2], // >= ratio ? prev[2] : ratio
          ],
          2: [
            prev[0], // >= ratio ? prev[0] : ratio,
            prev[1], // >= ratio ? prev[1] : ratio,
            ratio,
          ],
        }[edit_input] || prev
      );
    });
  };

  const doSetInput = (input, value) => {
    set_cap_time((prev) => {
      let varprev =
        {
          "0": [
            value,
            prev[1], //>= seconds ? prev[1] : seconds,
            prev[2], //>= seconds ? prev[2] : seconds
          ],
          "1": [
            prev[0], //>= seconds ? prev[0] : seconds,
            value,
            prev[2], //>= seconds ? prev[2] : seconds
          ],
          "2": [
            prev[0], //>= seconds ? prev[0] : seconds,
            prev[1], //>= seconds ? prev[1] : seconds,
            value,
          ],
        }[input] || prev;

      return varprev;
    });

    set_cap_slider((prev) => {
      let ratio = getRatioFromSeconds(value?.toString(), duration);

      return (
        {
          0: [
            ratio,
            prev[1], // >= ratio ? prev[1] : ratio,
            prev[2], // >= ratio ? prev[2] : ratio
          ],
          1: [
            prev[0], // >= ratio ? prev[0] : ratio,
            ratio,
            prev[2], // >= ratio ? prev[2] : ratio
          ],
          2: [
            prev[0], // >= ratio ? prev[0] : ratio,
            prev[1], // >= ratio ? prev[1] : ratio,
            ratio,
          ],
        }[edit_input] || prev
      );
    });
  };

  const getMilliseconds = (value) => {
    var decimal = value - Math.floor(value);
    return decimal > 0
      ? `${decimal
          ?.toFixed(3)
          ?.toString()
          .substr(1, decimal.toString().length)}`
      : `.000`;
  };

  const doRefresh = () => {
    clipplayerRef?.current?.seekTo(cap_time[0]?.toString(), "seconds"); //reset playhead to the start cap
    if (!clipplayer_state?.isPlaying) {
      set_cap_time((prev) => [
        prev[0],
        prev[0], //reset playhead to match clip start
        prev[2],
      ]);
      set_cap_slider((prev) => [
        prev[0],
        prev[0], //reset playhead to match clip start
        prev[2],
      ]);
    }
  };

  const doSeek = (seekto, x) => {
    clipplayerRef?.current?.seekTo(seekto, "seconds");
    set_clipplayer_state((prev) => {
      return { ...prev, seek: seekto, seek_slider: x };
    });
    return;
  };

  const onReady = () => {};

  const onProgress = async (state) => {
    // console.log("state",state?.playedSeconds,"currenttime",clipplayerRef?.current?.getCurrentTime())

    //underage
    if (state.playedSeconds < cap_time[0]) {
      // console.log("underage0",cap,state.playedSeconds)

      clipplayerRef?.current?.seekTo(cap_time[0], "seconds");

      await set_clipplayer_state((prev) => {
        return { ...prev, seek: cap_time[0], seek_slider: cap_slider[0] };
      });
      await set_cap_time((prev) => [prev[0], cap_time[0], prev[2]]);
      await set_cap_slider((prev) => [prev[0], cap_slider[0], prev[2]]);

      return;
    }

    //overage
    if (state.playedSeconds > cap_time[2]) {
      clipplayerRef?.current?.seekTo(cap_time[0], "seconds");

      await set_clipplayer_state((prev) => {
        return { ...prev, seek: cap_time[0], seek_slider: cap_slider[0] };
      });
      await set_cap_time((prev) => [prev[0], cap_time[0], prev[2]]);
      await set_cap_slider((prev) => [prev[0], cap_slider[0], prev[2]]);

      return;
    }

    if (
      state.playedSeconds > cap_time[0] &&
      state.playedSeconds <= cap_time[2]
    ) {
      //good to go
      await set_clipplayer_state((prev) => {
        return {
          ...prev,
          seek: state.playedSeconds,
          seek_slider: state.played * 100,
        };
      });
      await set_cap_time((prev) => [prev[0], state.playedSeconds, prev[2]]);
      await set_cap_slider((prev) => [prev[0], state.played * 100, prev[2]]);
    }
  };

  const togglePlay = (num = 0) => {
    set_clipplayer_state((prev) => {
      return {
        ...prev,
        isPlaying: num == 0 ? !prev.isPlaying : num == 1 ? true : false,
      };
    });
  };

  //use this to see if we have any changes in the markers so we can update the marker visuals
  const onBeforeChange = async (x_arr) => {
    set_last_cap_time([
      getSecondsFromRatio(x_arr[0], duration),
      getSecondsFromRatio(x_arr[1], duration),
      getSecondsFromRatio(x_arr[2], duration),
    ]);
  };

  const onChange = async (x_arr) => {
    set_cap_slider(x_arr);
    set_cap_time([
      getSecondsFromRatio(x_arr[0], duration),
      getSecondsFromRatio(x_arr[1], duration),
      getSecondsFromRatio(x_arr[2], duration),
    ]);

    //function to update player_state for middle
    let seconds = await getSecondsFromRatio(x_arr[1], duration);
    let ratio = await getRatioFromSeconds(seconds, duration);
    set_changes(true);

    if (edit_input == 1) {
      await doSeek(seconds, ratio);
    }
  };

  const handleSubmit = async () => {
    if (cap_time[2] <= cap_time[0]) {
      toast(config.clip.error_order_warning, {
        time: 3000,
        className: "",
        clickable: true,
        clickClosable: false,
      });
      return false;
    }
    if (cap_time[2] - cap_time[0] > config.clip.max_duration) {
      toast(config.clip.max_duration_warning, {
        time: 3000,
        className: "",
        clickable: true,
        clickClosable: false,
      });
      return false;
    }

    await set_submitting(true);
    toast(
      `Your clip is being generated on our server. This may take a few moments, so please don't close this window.`,
      { time: 3000, className: "", clickable: true, clickClosable: false }
    );

    //console.log("submission",{start: cap_time[0], end: cap_time[2], file: audio_url})
    const res = await fetch("/api/private/upload/clip", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start: cap_time[0],
        end: cap_time[2],
        file: audio_url,
        post_id: itemdata?.post_id,
      }),
    });

    const json = await res.json();
    // console.log("json",json)
    if (json) {
      const {
        outcome,
        outputURL_audio_mp3,
        outputURL_audio_mp4,
        outputURL_waveform,
        url_audio,
        hash,
        size,
        crdate,
        source,
        duration,
        filename,
        extention,
        post_type,
        upload_id,
        metadata_json,
        exists,
      } = json;

      // console.log(outcome)
      if (outcome?.toString() == "1") {
        set_submitting(false);
        ``;
        set_changes(false);
        set_clip_state((prev) => {
          return { ...prev, status: 2 };
        });
        set_clip_json([
          {
            outputURL_audio_mp3: outputURL_audio_mp3,
            outputURL_audio_mp4: outputURL_audio_mp4,
            outputURL_waveform: outputURL_waveform,
            url: url_audio,
            hash: hash,
            size: size,
            crdate: crdate,
            source: source,
            duration: duration,
            filename: filename,
            extention: extention,
            post_type: post_type,
            upload_id: upload_id,
            metadata_json: metadata_json,
            exists: exists,
            clip_start: cap_time[0],
            clip_end: cap_time[2],
          },
        ]);
        toast(`Clip successful! Now tell us a little bit about your clip.`, {
          time: 3000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
      }
    }
  };

  return (
    <>
      <div className="w-full z-[1000]">
        <div className="hidden">
          <ReactPlayer
            ref={clipplayerRef}
            url={srcSafe(audio_url)}
            config={{
              forceAudio: true,
            }}
            width="100%"
            height="40px"
            autoPlay={clipplayer_state?.isMediaLoaded}
            preload={"auto"}
            controls={false}
            playing={clipplayer_state.isPlaying}
            muted={clipplayer_state?.isMuted}
            volume={clipplayer_state?.volume}
            playbackRate={clipplayer_state?.playbackRate}
            onReady={onReady}
            // onReady={onReady}
            progressInterval={1}
            onProgress={onProgress}
            // onStart={onStart}
            onPlay={() => {
              set_clipplayer_state({
                ...clipplayer_state,
                isPlaying: true,
                isSeeking: false,
                isLoading: false,
                isMediaLoaded: true,
              });
            }}
            onSeek={() => {
              set_clipplayer_state({ ...clipplayer_state, isSeeking: true });
            }}
            onError={(e) => console.log("error", e)}
            onBuffer={() =>
              set_clipplayer_state({ ...clipplayer_state, isBuffering: true })
            }
            onBufferEnd={() =>
              set_clipplayer_state({ ...clipplayer_state, isBuffering: false })
            }
            //forceAudio
          />
        </div>

        <div className="flex  items-center content-center w-full mb-4">
          <div className="flex-1 ">
            <div className=" flex items-center content-center w-full">
              <div
                className=""
                onClick={async () => {
                  await togglePlay(0);
                }}
              >
                <SvgJsx
                  type={"fill"}
                  icon={clipplayer_state?.isPlaying ? "pause-sm" : "play-sm"}
                  className={`cursor-pointer h-20 w-20 group-hover:opacity-80 group-hover:animate-wiggle`}
                  title={clipplayer_state?.isPlaying ? "pause" : "play"}
                />
              </div>
              <div
                className={``}
                onClick={async () => {
                  await doRefresh();
                }}
              >
                <SvgJsx
                  type={"fill"}
                  icon={
                    clipplayer_state?.isPlaying ? "refresh-sm" : "refresh-sm"
                  }
                  className={`cursor-pointer hover:opacity-80 hover:animate-spin h-6 w-6 `}
                  title={clipplayer_state?.isPlaying ? "restart" : "disabled"}
                />
              </div>

              <div className="flex-1 w-full hidden sm:block font-bold text-3xl ml-4">
                {timerStr(cap_time[1])}
                {getMilliseconds(cap_time[1])}
              </div>
            </div>
          </div>

          <div className="flex-0 w-32">
            <div className="w-full flex items-center content-center  cursor-pointer">
              <div
                onClick={() => {
                  set_volume_slider(0);
                  set_clipplayer_state((prev) => {
                    return { ...prev, volume: 0 };
                  });
                }}
              >
                <SvgJsx
                  type={"fill"}
                  icon={"volume-up-sm"}
                  className={` h-3 w-3 mr-1 ${
                    clipplayer_state?.volume == 0
                      ? "opacity-20"
                      : "hover:opacity-80"
                  }`}
                  title={"Zoom out"}
                />
              </div>
              <div className="flex-1 pb-2 w-full">
                <ReactSlider
                  ariaLabelledby="slider-label"
                  className="horizontal-slider"
                  thumbClassName="volumethumb"
                  trackClassName="volumetrack"
                  renderTrack={(props, state) => (
                    <div
                      {...props}
                      className={`${
                        props.key == `volumetrack-${0}`
                          ? "bg-blue-400"
                          : "bg-gray-400"
                      } h-2 rounded-full  cursor-pointer`}
                    ></div>
                  )}
                  renderThumb={(props, state) => (
                    <div
                      {...props}
                      className="bg-white -top-1 w-4 h-4 rounded-full text-3xs text-black cursor-pointer"
                    ></div>
                  )}
                  onChange={async (x) => {
                    set_volume_slider(Math.floor(x));
                    set_clipplayer_state((prev) => {
                      return { ...prev, volume: x / 100 };
                    });
                  }}
                  defaultValue={volume_slider}
                  value={volume_slider}
                  //pearling={true}
                  minDistance={0}
                  step={1}
                />
              </div>
              <div
                className="text-gray-300 text-2xs ml-1 cursor-pointer"
                onClick={() => {
                  set_volume_slider(100);
                  set_clipplayer_state((prev) => {
                    return { ...prev, volume: 1 };
                  });
                }}
              >
                {Math.floor(clipplayer_state?.volume * 100)}%
              </div>
            </div>
            <div className="block mt-2">
              <select
                className="font-normal bg-gray-900 text-white border border-white rounded-md ml-1 text-xs py-0.5 w-32"
                defaultValue={clipplayer_state?.playbackRate}
                onChange={async (e) => {
                  await set_clipplayer_state((prev) => {
                    return {
                      ...prev,
                      playbackRate: parseFloat(e.target.value),
                      isPlaying: true,
                    };
                  });
                }}
              >
                {playbackRate_array.map((e, index) => {
                  return (
                    <option key={index} value={e.speed}>
                      {e.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="sm:hidden mt-2 text-right text-sm whitespace-nowrap flex items-center content-center">
              <div className="flex-1">
                {timerStr(cap_time[1])}
                {getMilliseconds(cap_time[1])}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex items-center content-center  pb-1 text-xs text-gray-300">
            <div className="flex-1">{timerStr(0)}</div>
            <div className="flex-1 text-right">{timerStr(duration)}</div>
          </div>

          <div className="w-full mb-1">
            <ReactSlider
              ariaLabelledby="slider-label"
              className="horizontal-slider"
              thumbClassName="seekthumb"
              trackClassName="seektrack"
              renderTrack={(props, state) => (
                <div
                  {...props}
                  className={`${
                    {
                      "seektrack-0": "bg-gray-600",
                      "seektrack-1": "bg-white",
                      "seektrack-2": "bg-white",
                    }[props.key] || "bg-gray-600"
                  } h-4 rounded-full`}
                ></div>
              )}
              renderThumb={(props, state) => {
                return (
                  <div
                    {...props}
                    className={`cursor-pointer border  
                                                        
                                                                ${
                                                                  {
                                                                    "seekthumb-0":
                                                                      "bg-green-500",
                                                                    "seekthumb-1":
                                                                      "bg-blue-500",
                                                                    "seekthumb-2":
                                                                      "bg-yellow-500",
                                                                  }[
                                                                    props.key
                                                                  ] ||
                                                                  "bg-gray-500"
                                                                }

                                                                ${
                                                                  state.index ==
                                                                  edit_input
                                                                    ? "border-white"
                                                                    : "border-transparent"
                                                                }
                                                                   
                                                                 w-4 h-4 rounded-full`}
                    onClick={() => {
                      set_edit_input(state.index);
                    }}
                    onChange={() => {
                      set_edit_input(state.index);
                    }}
                    onFocus={() => {
                      set_edit_input(state.index);
                    }}
                  />
                );
              }}
              onBeforeChange={onBeforeChange}
              onChange={onChange}
              defaultValue={cap_slider}
              value={cap_slider}
              pearling={true}
              minDistance={0}
              step={0.001}
            />
          </div>
        </div>

        <div
          className={`border border-gray-500 ${
            cap_time[2] <= cap_time[1] ||
            cap_time[2] - cap_time[0] > config.clip.max_duration
              ? "rounded-t-md"
              : "rounded-md"
          } mt-8 sm:flex p-4
            
             ${
               {
                 0: "items-start content-top",
                 1: "items-center content-center",
                 2: "items-end content-bottom",
               }[edit_input]
             }
            
            `}
        >
          <div className="max-w-[600px] w-full items-center content-center justify-center flex flex-col gap-y-2 ">
            {[thumbStartRef, thumbTrackRef, thumbEndRef].map((e, index) => {
              return (
                <PostClipInput
                  timerStr={timerStr}
                  cap_time={cap_time}
                  getMilliseconds={getMilliseconds}
                  set_edit_input={set_edit_input}
                  doSetInput={doSetInput}
                  edit_input={edit_input}
                  applyCap={applyCap}
                  input={index}
                  key={index}
                  duration={duration}
                />
              );
            })}
          </div>
          <div className="flex flex-col gap-y-2 mt-8 justify-center">
            {["-", "+"].map((pm, index) => {
              return (
                <InputIncrementButton
                  key={pm}
                  doSetInput={doSetInput}
                  edit_input={edit_input}
                  plus_minus={pm}
                  cap_time={cap_time}
                  duration={duration}
                />
              );
            })}
          </div>
        </div>

        <div
          className={`
                   ${
                     cap_time[2] <= cap_time[0] ||
                     cap_time[2] - cap_time[0] > config.clip.max_duration
                       ? "visible"
                       : "invisible"
                   }
                   
                   bg-red-600 shadow-sm h-8 text-white italic text-md mt-0 px-4 py-1 rounded-b-md text-center`}
        >
          {cap_time[2] <= cap_time[0]
            ? "Clip Start exceeds Clip End!"
            : cap_time[2] - cap_time[0] > config.clip.max_duration
            ? "Clips cannot exceed 15 minutes!"
            : ""}
        </div>

        <div className="flex-0 flex items-center content-center mt-5">
          <div className="mt-4 sm:mt-0">
            <div
              role="button"
              aria-pressed="false"
              tabIndex={0}
              onKeyDown={(e) => {
                e.preventDefault();
                if (submitting) return false;
                if ([13, 32].indexOf(e.keyCode) > -1) handleSubmit(); //enter / return
              }}
              className={`
                            ${
                              cap_time[2] > cap_time[0] &&
                              cap_time[2] - cap_time[0] <
                                config.clip.max_duration
                                ? submitting
                                  ? "bg-gray-600 cursor-not-allowed"
                                  : "bg-blue-600 cursor-pointer"
                                : "bg-gray-300 opacity-50 cursor-not-allowed"
                            } 
                        
                        
                        rounded-md text-white font-bold text-2xl px-4 py-2 whitespace-nowrap text-center flex`}
              onClick={(e) => {
                e.preventDefault();
                if (submitting) return false;
                handleSubmit();
              }}
            >
              <LoaderToggle
                loadingstate={submitting}
                actionText={"Save a Clip"}
                actionTextShort={"Save a Clip"}
                loadingClass="text-gray-400 w-7 h-7"
                toggle={{
                  bgColor: "transparent",
                  centerColor: "transparent",
                  holeColor: "transparent",
                  pointerColor: "#ffffff",
                  dialerColor: "#ffffff77",
                }}
              />
            </div>
          </div>

          <div className="flex-1"></div>

          <div className=" sm:ml-5 mt-5 xs:mt-0 text-right ">
            <div className="font-bold text-xs xs:text-md ">Output Length</div>
            <div
              className={`text-lg xs:text-xl  ${
                cap_time[2] - cap_time[0] > config.clip.max_duration
                  ? "text-red-600"
                  : "text-white"
              }`}
            >
              {`${timerStr(cap_time[2] - cap_time[0])}${getMilliseconds(
                cap_time[2] - cap_time[0]
              )}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PostClipInput({
  timerStr,
  cap_time,
  getMilliseconds,
  set_edit_input,
  doSetInput,
  edit_input,
  applyCap,
  input,
  duration,
}) {
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchRef?.current) searchRef?.current?.focus();
  }, [searchRef?.current]);

  return (
    <>
      <div className="w-full  p-1 ">
        <div className="flex items-center content-center group">
          <div className={` w-full `}>
            <div>
              <label htmlFor={`input${input}`}>
                <div
                  className={`sm:flex items-center content-center px-1
                                ${
                                  {
                                    0: "text-green-500",
                                    1: "text-blue-500",
                                    2: "text-yellow-500",
                                  }[input]
                                }
                                
                                            ${
                                              edit_input == input
                                                ? "font-bold "
                                                : ""
                                            }`}
                >
                  <div className="flex-1">
                    {
                      {
                        0: "Clip Start",
                        1: "Track",
                        2: "Clip End",
                      }[input]
                    }
                  </div>
                  <div className=" text-sm italic">
                    {timerStr(cap_time[input])}
                    {getMilliseconds(cap_time[input])}
                  </div>
                </div>
              </label>
            </div>
            <div className="flex items-center content-center">
              <input
                autoFocus={true}
                ref={searchRef}
                type="number"
                id={`input${input}`}
                name={`input${input}`}
                value={parseFloat(cap_time[input])?.toFixed(3)}
                min={0.0}
                max={
                  {
                    0: cap_time[2], //out value is the max
                    1: cap_time[2], //out value is the max
                    2: duration, //duration value is the max
                  }[input]
                }
                step={0.001}
                onFocus={(e) => set_edit_input(input)}
                onChange={(e) => {
                  applyCap(e.target.value, 0);
                  doSetInput(input, e.target.value);
                }}
                className={` w-full border-0 rounded-md px-2
                                ${
                                  edit_input == input
                                    ? `${
                                        {
                                          0: "bg-green-600 active:border-green-500",
                                          1: "bg-blue-600 active:border-blue-500",
                                          2: "bg-yellow-600 active:border-yellow-500",
                                        }[input]
                                      }   `
                                    : " border-transparent bg-gray-700"
                                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function InputIncrementButton({
  doSetInput,
  edit_input,
  plus_minus,
  cap_time,
  duration,
}) {
  const values_array = () => [
    { t: "1s", v: "1" },
    { t: "10s", v: "10" },
    { t: "30s", v: "30" },
    { t: "1m", v: "60" },
    { t: "5m", v: "300" },
  ];

  const new_increment = (val) => {
    return parseInt(val) * (plus_minus == "+" ? 1 : -1);
  };

  const isViable = (val) => {
    var min = {
      0: 0, //0 is the min
      1: Math.max(parseFloat(cap_time[0]?.toString()), 0), //in value is the min
      2: Math.max(parseFloat(cap_time[0]?.toString()), 0), //in value is the min
    }[edit_input];

    var new_value =
      parseFloat(cap_time[edit_input]?.toString()) + new_increment(val);

    var max = {
      0: Math.min(parseFloat(cap_time[2]?.toString()), duration), //out value is the max
      1: Math.min(parseFloat(cap_time[2]?.toString()), duration), //out value is the max
      2: duration, //duration value is the max
    }[edit_input];

    if (
      (plus_minus == "+" && new_value > max) ||
      (plus_minus == "-" && new_value < min)
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="flex flex-col items-center content-center w-full cursor-pointer ml-5">
        <div className="flex items-center content-center  gap-x-2 mr-5">
          {values_array().map((e) => {
            let is_this_viable = isViable(e?.v);

            return (
              <button
                key={e?.v}
                disabled={!is_this_viable}
                className={`flex items-center content-center justify-center text-xs text-white border border-gray-500  rounded-md h-6 w-10 p-1 text-center ${
                  !is_this_viable ? "opacity-20" : "hover:border-white"
                }`}
                onClick={() => {
                  if (!is_this_viable) return false;
                  //good to proceed
                  //doIncrement(edit_input, new_increment(e?.v));
                  doSetInput(
                    edit_input,
                    parseFloat(cap_time[edit_input]?.toString()) +
                      new_increment(e?.v)
                  );
                }}
              >
                <div>
                  {plus_minus}
                  {e?.t}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}
