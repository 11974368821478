import ModalForm from "@/components/templateux/modal/modal-form";
import { config, lookupPost, title_format } from "@/lib/config";
import { getSlugId } from "@/lib/utils/getSlugFilter";
import { hasData } from "@/lib/utils/hasData";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import isAdminUser, { isAdminObject } from "@/lib/utils/isAdminUser";
import { slugify } from "@/lib/utils/slugify";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import PostFormContainer from "./post-form-container";
import PostMeta from "./post-meta";
import PostSingle from "./post-single";
import PostSingleAudioOnly from "./post-single-audio-only";
import PostClipEdit from "./post-clip-edit";
import { cleanGuestJson } from "@/lib/utils/cleanGuestJson";
import { extractJson } from "@/lib/utils/extractJson";
import Link from "next/link";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import ShareSocialWrapper from "@/components/templateux/share/share-social-wrapper";
import { textProcess } from "@/lib/utils/textprocess";

export default function PostStateBreakdown({
  headline,
  itemdata,
  itemdataisLoading,
  itemdataisError,
  userdata,
  ownerdata,
  ssr_data_tribe,
  display_view,
  cache,
  set_cache,
  nodata,
  navtype,
  helptype,
  slug,
  router,
  descriptionRef,
  index,
  isExpanded,
  origin_url,
  origin_title,
  ssr_isadmin,
}) {
  const { isConfirmed } = useConfirm();
  const metaRef = useRef(null);
  const scrollTo = (ref) =>
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  const metaScroll = () => scrollTo(metaRef);
  const [isAuthor, set_isAuthor] = useState(false);
  const [isDraft, set_isDraft] = useState(
    itemdata?.bol_public?.toString() !== "1"
  );
  const [likes, set_likes] = useState(itemdata?.likes);
  const [comments, set_comments] = useState(itemdata?.comments);
  const [opens, set_opens] = useState(itemdata?.opens);
  const [clip_state, set_clip_state] = useState({
    status: 0,
    auto: 1,
    start: 0,
    track: 0,
    end: 0,
  });
  const [clip_json, set_clip_json] = useState([]);
  const [ssr_isadmin_active, set_ssr_isadmin_active] = useState(ssr_isadmin);

  useEffect(() => {
    set_comments(itemdata?.comments);
    set_likes(itemdata?.likes);
    set_opens(itemdata?.opens);
    set_isDraft(itemdata?.bol_public?.toString() !== "1");
    set_ssr_isadmin_active(
      isAdminObject(
        itemdata?.user_id?.toString(), //user_id
        itemdata?.bol_staff_help, //bol_staff_help
        itemdata?.staff_json, //staff_json
        itemdata?.bol_admin_help, //bol_admin_help
        userdata?.id?.toString(), //my_id
        userdata?.bol_admin?.toString() //global_admin
      )
    );
  }, [itemdata, userdata]);

  //HOOKS ABOVE SECURITY CALLS
  const [isOpen, set_isOpen] = useState(null); //modal

  //COMMENTS
  const [tabs, set_tabs] = useState("response");

  //SUBMISSION STATES
  const [submitting, set_submitting] = useState(false);
  const [changes, set_changes] = useState(false);
  const [post_item, set_post_item] = useState();

  //SUBMISSION STATES
  const [processing, set_processing] = useState(-1);
  const [form_reset, set_form_reset] = useState(1);
  const [listen, set_listen] = useState({ file: "", post_id: "", user_id: "" });
  const [open, set_open] = useState(false);
  const [typevalue, set_typevalue] = useState(0);
  const [modal_type, set_modal_type] = useState("form");

  function validTab(n) {
    if (!hasData(n)) return false;
    if (
      [
        "response",
        "open",
        "stat",
        "transcript",
        "replay",
        "meta",
        "clip",
        "kudos",
      ].indexOf(n) > -1
    )
      return true;
    return false;
  }

  useEffect(() => {
    if (validTab(router.query?.show?.toLowerCase()))
      set_tabs(router.query?.show?.toLowerCase());
  }, [router.query?.show]);

  const [display_data, set_display_data] = useState(itemdata);
  const [post_date, set_post_date] = useState(new Date());
  const [permaurl, set_permaurl] = useState(
    `/${itemdata?.user_name?.toLowerCase()}/posts`
  );
  const [toggle, set_toggle] = useState(itemdata?.bol_public);
  const [broadcast_object, set_broadcast_object] = useState({
    url: "",
    hash: "",
    size: "",
    source: "",
    category: "",
    duration: "",
    end_date: "",
    extention: "",
    post_type: "",
    thumbnail: "",
    start_date: "",
    metadata_json: "",
    user_id: 0,
    user_name: "",
    timestamp: "",
    avatar_url: "",
    content_id: 0,
    content_table: "post",
    guests_json: "[]",
  });

  //followers
  const [state_following_them, set_state_following_them] = useState(false);
  const [state_following_them_status, set_state_following_them_status] =
    useState(0);

  useEffect(() => {
    set_display_data(itemdata);
    set_likes(itemdata?.likes);
    set_opens(itemdata?.opens);
    set_post_date(new Date(itemdata?.publish_date));
    set_permaurl(
      `/${itemdata?.user_name?.toLowerCase()}/posts/${slugify(
        itemdata?.post_id,
        itemdata?.post_title
      )}`
    );
    set_toggle(itemdata?.bol_public);

    if (hasData(itemdata?.post_contents_json)) {
      // console.log("itemdata?.post_contents_json",cleanGuestJson(itemdata?.post_contents_json))
      set_broadcast_object(
        JSON.parse(cleanGuestJson(itemdata?.post_contents_json))[0]
      );
    } else {
      if (hasData(itemdata?.broadcast_contents_json)) {
        //console.log("itemdata?.broadcast_contents_json",cleanGuestJson(itemdata?.broadcast_contents_json))
        set_broadcast_object(
          JSON.parse(cleanGuestJson(itemdata?.broadcast_contents_json))[0]
        );
      }
    }

    set_state_following_them(
      hasData(itemdata?.following_them)
        ? parseInt(itemdata?.following_them) > 0
        : false
    );
    set_state_following_them_status(
      hasData(itemdata?.following_them_status)
        ? parseInt(itemdata?.following_them_status)
        : 1
    );
  }, [itemdata]);

  //MODAL FUNCTION
  async function closeModal() {
    if (changes) {
      const confirmed = await confirm(
        "You have unsaved changes. Are you sure you want to close?"
      );
      //const confirmed = await isConfirmed('You have unsaved changes. Are you sure you want to close?');
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
      if (confirmed) {
        set_isOpen(null);
        set_form_reset(1);
      }
    } else {
      set_isOpen(null);
      set_form_reset(1);
    }
  }

  //MODAL FUNCTION
  async function closeModalPost() {
    set_isOpen(null);
    //updateTitle(title_format(origin_title,ssr_data_tribe)); //closes modal, so updates history after state changes
    window.history.pushState(
      "object or string",
      title_format(origin_title, ssr_data_tribe),
      origin_url
    );
    //updateTitle(title_format(origin_title,ssr_data_tribe)); //closes modal, so updates history after state changes
  }

  function openModal(type, n, value) {
    set_modal_type(type);
    set_typevalue(value);
    set_isOpen(n);
  }

  useEffect(() => {
    if (itemdata && userdata) {
      set_isDraft(itemdata?.bol_public?.toString() !== "1");
      set_isAuthor(itemdata?.user_id?.toString() == userdata?.id?.toString());
    }
  }, [itemdata, userdata]);

  async function submitHandler(
    submitbody,
    api_destination,
    api_method = "POST",
    api_headers = {
      "Content-Type": "application/json",
    },
    toast_message = "Your changes were saved."
  ) {
    set_submitting(true);

    //console.log(JSON.stringify(submitbody))

    try {
      //INFO SUBMIT

      set_submitting(true);
      const res = await fetch(api_destination, {
        method: api_method,
        headers: api_headers,
        body: JSON.stringify(submitbody),
      });

      //console.log("res",res)

      set_changes(false);
      set_submitting(false);
      const json = await res.json();
      if (json) {
        const { outcome, post_id, post_title, slug, user_name } = json[0][0];
        // console.log(json,submitbody);
        if (outcome?.toString() == "1") {
          toastConfig({
            time: 5000,
            className: "",
            position: "right",
          });
          toast(toast_message, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          console.log("json error", outcome);
        }
        set_cache(+new Date());
        set_isOpen(null);
        if (submitbody?.post_id?.toString() == "0")
          router.push(`/${user_name}/posts/${slugify(post_id, slug)}`);
      }
    } catch (e) {
      throw Error(e.message);
    }
  }

  const deleteHandler = async (item_id) => {
    if (!ssr_isadmin_active[helptype]) {
      toast(`You do not have permission to delete this.`, {
        time: 1000,
        className: "",
        clickable: true,
        clickClosable: false,
      });
      return -2;
    }

    if (hasData(item_id)) {
      const confirmed = await isConfirmed(
        "Are you sure you want to delete this?"
      );
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
      if (confirmed) {
        const res = await fetch(
          `/api/private/post/delete?id=${item_id}&del=1`,
          { method: "DELETE" }
        );
        const json = await res.json();

        if (json) {
          // console.log("json",json)

          const { outcome } = json;
          if (outcome == 1) {
            toast(`Post deleted`, {
              time: 1000,
              className: "",
              clickable: true,
              clickClosable: false,
            });

            //new cache to trigger updated swr call
            const new_cache = new Date();
            set_cache(encodeURIComponent(new_cache.toString()));
          }
        }
      }
    }
  };

  const stickyHandler = useCallback(
    async (item_id) => {
      if (!ssr_isadmin_active[helptype]) {
        toast(`You do not have permission to sticky this.`, {
          time: 1000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
        return -2;
      }

      if (hasData(item_id)) {
        const confirmed = await isConfirmed(
          "Are you sure you want to sticky this?"
        );
        // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
        if (confirmed) {
          const res = await fetch(
            `/api/private/post/update-sticky?id=${item_id}`,
            { method: "POST" }
          );
          const json = await res.json();

          if (json) {
            //console.log("json",json)

            const { action } = json[0][0];
            if (hasData(action)) {
              if (action == 1) {
                toast(`Post stickied!`, {
                  time: 1000,
                  className: "",
                  clickable: true,
                  clickClosable: false,
                });
              } else if (action == 0) {
                toast(`Post unstickied!`, {
                  time: 1000,
                  className: "",
                  clickable: true,
                  clickClosable: false,
                });
              }
              //new cache to trigger updated swr call
              const new_cache = new Date();
              set_cache(encodeURIComponent(new_cache.toString()));
            } else {
              toast(`Something went wrong. Please try again.`, {
                time: 1000,
                className: "",
                clickable: true,
                clickClosable: false,
              });
            }
          }
        }
      }
    },
    [ssr_isadmin_active[helptype], isConfirmed, set_cache, toast]
  );

  const transcribeHandler = useCallback(
    async (item_id) => {
      if (!ssr_isadmin_active[helptype]) {
        toast(`You can only transcribe your own content!`, {
          time: 1000,
          className: "",
          clickable: true,
          clickClosable: false,
        });
        return -2;
      }

      if (hasData(item_id)) {
        const confirmed = await isConfirmed(
          "Are you sure you want to transcribe this file? This may take a few minutes."
        );
        // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
        if (confirmed) {
          const res = await fetch(
            `/api/private/post/update-transcribe?id=${item_id}`,
            { method: "POST" }
          );
          const json = await res.json();

          if (json) {
            //console.log("json",json)

            const { action } = json;
            if (hasData(action)) {
              if (action == 1) {
                toast(`Transcription initiated! Check back in a few minutes`, {
                  time: 1000,
                  className: "",
                  clickable: true,
                  clickClosable: false,
                });
              } else if (action == 0) {
                toast(`Transcription unsuccessful!`, {
                  time: 1000,
                  className: "",
                  clickable: true,
                  clickClosable: false,
                });
              }
              //new cache to trigger updated swr call
              const new_cache = new Date();
              set_cache(encodeURIComponent(new_cache.toString()));
            } else {
              toast(`Something went wrong. Please try again.`, {
                time: 1000,
                className: "",
                clickable: true,
                clickClosable: false,
              });
            }
          }
        }
      }
    },
    [ssr_isadmin_active[helptype], isConfirmed, set_cache, toast]
  );

  //post view (default)
  return (
    <>
      <div className=" justify-center mx-auto w-full  text-center">
        {itemdata?.length == 0 && (
          <div className="w-full px-4 py-5 mt-3 text-center text-md  text-white ">
            {textProcess(nodata)}
          </div>
        )}

        <div className=" justify-center flex flex-col">
          {display_view == "posts" && (
            <>
              <div className="mb-4">
                <PostSingle
                  router={router}
                  itemdata={itemdata}
                  userdata={userdata}
                  ownerdata={ownerdata}
                  display_view={display_view}
                  openModal={openModal}
                  closeModal={closeModal}
                  cache={cache}
                  set_cache={set_cache}
                  navtype={navtype}
                  helptype={helptype}
                  tabs={tabs}
                  set_tabs={set_tabs}
                  slug={slug}
                  deleteHandler={deleteHandler}
                  stickyHandler={stickyHandler}
                  transcribeHandler={transcribeHandler}
                  isDraft={isDraft}
                  set_isDraft={set_isDraft}
                  ssr_isadmin={ssr_isadmin_active}
                  isAuthor={isAuthor}
                  likes={likes}
                  set_likes={set_likes}
                  comments={comments}
                  set_comments={set_comments}
                  opens={opens}
                  set_opens={set_opens}
                  descriptionRef={descriptionRef}
                  index={index}
                  clip_state={clip_state}
                  set_clip_state={set_clip_state}
                  isOpen={isOpen}
                  isExpanded={isExpanded}
                  isFlat={true}
                  metaScroll={metaScroll}
                />
              </div>
            </>
          )}

          {display_view == "audio" && (
            <>
              <PostSingleAudioOnly
                router={router}
                itemdata={itemdata}
                userdata={userdata}
                ownerdata={ownerdata}
                display_view={display_view}
                openModal={openModal}
                closeModal={closeModal}
                cache={cache}
                set_cache={set_cache}
                navtype={navtype}
                helptype={helptype}
                tabs={tabs}
                set_tabs={set_tabs}
                slug={slug}
                deleteHandler={deleteHandler}
                stickyHandler={stickyHandler}
                transcribeHandler={transcribeHandler}
                isDraft={isDraft}
                set_isDraft={set_isDraft}
                ssr_isadmin={ssr_isadmin_active}
                isAuthor={isAuthor}
                clip_state={clip_state}
                set_clip_state={set_clip_state}
              />
            </>
          )}

          {hasData(slug) && (
            <div className="mt-2" id={`postmeta-${itemdata?.post_id}`}>
              <PostMeta
                profiledata={ownerdata}
                itemdata={itemdata}
                tabs={tabs}
                set_tabs={set_tabs}
                post_id={getSlugId(slug)}
                slug={slug}
                router={router}
                cache={cache}
                set_cache={set_cache}
                userdata={userdata}
                ownerdata={ownerdata}
                ssr_data_tribe={ssr_data_tribe}
                descriptionRef={descriptionRef}
                nodata={nodata}
                navtype={navtype}
                helptype={helptype}
                comments={comments}
                set_comments={set_comments}
                likes={likes}
                opens={opens}
                isExpanded={true}
                isFlat={true}
                permaurl={permaurl}
                origin_url={origin_url}
                origin_title={origin_title}
                clip_state={clip_state}
                set_clip_state={set_clip_state}
                openModalClip={openModal}
                ssr_isadmin={ssr_isadmin_active}
              />
            </div>
          )}
        </div>
      </div>

      <ModalForm
        title={
          modal_type == "share" ? (
            <>
              <div className="flex items-center content-center">
                <div>
                  <SvgJsx
                    type="outline"
                    icon="share"
                    className={`  w-5 h-5
                                  `}
                    title="Share"
                  />
                </div>
                <div className="ml-2">Share Post</div>
              </div>
            </>
          ) : modal_type == "download" ? (
            `Download Files`
          ) : modal_type == "post" ? (
            <>
              <Link
                href={`/${itemdata?.user_name?.toLowerCase()}/posts/${slugify(
                  itemdata?.post_id,
                  itemdata?.post_title
                )}`}
                className="cursor-pointer hover:underline"
              >
                {itemdata?.post_title}
              </Link>
            </>
          ) : modal_type == "clip" ? (
            `Create a Clip`
          ) : isOpen == 0 ? (
            `New ${lookupPost("", typevalue)?.text}`
          ) : (
            `Edit ${lookupPost("", typevalue)?.text}`
          )
        }
        modal_content={
          modal_type == "share" ? (
            <>
              <ShareSocialWrapper
                url={`${ssr_data_tribe?.server_website}/${
                  itemdata?.user_name
                }/posts/${slugify(itemdata?.post_id, itemdata?.post_title)}`}
                image_url={itemdata?.avatar_url}
                title={`${ssr_data_tribe?.brand_name} Post: ${itemdata?.post_title}`}
                description={itemdata?.post_description}
                hashtags={[
                  `#${ssr_data_tribe?.brand_name}_post`,
                  `#${ssr_data_tribe?.brand_name}`,
                  `#post`,
                ]}
                source={`${ssr_data_tribe?.brand_name}`}
              />
            </>
          ) : modal_type == "post" ? (
            <>
              <div className="w-full">
                <div className="flex-col items-center content-center w-full">
                  <PostSingle
                    router={router}
                    itemdata={itemdata}
                    userdata={userdata}
                    ownerdata={ownerdata}
                    display_view={display_view}
                    openModal={openModal}
                    closeModal={closeModal}
                    cache={cache}
                    set_cache={set_cache}
                    navtype={navtype}
                    helptype={helptype}
                    tabs={tabs}
                    set_tabs={set_tabs}
                    slug={slug}
                    deleteHandler={deleteHandler}
                    stickyHandler={stickyHandler}
                    transcribeHandler={transcribeHandler}
                    isDraft={isDraft}
                    set_isDraft={set_isDraft}
                    ssr_isadmin={ssr_isadmin_active}
                    isAuthor={isAuthor}
                    likes={likes}
                    set_likes={set_likes}
                    comments={comments}
                    set_comments={set_comments}
                    opens={opens}
                    set_opens={set_opens}
                    descriptionRef={descriptionRef}
                    index={index}
                    clip_state={clip_state}
                    set_clip_state={set_clip_state}
                    isOpen={isOpen}
                    isExpanded={true}
                    isFlat={false}
                    metaScroll={metaScroll}
                  />
                </div>
                <div
                  className="w-full mt-2"
                  id={`postmeta-${itemdata?.post_id}`}
                  ref={metaRef}
                >
                  <PostMeta
                    profiledata={ownerdata}
                    itemdata={itemdata}
                    tabs={tabs}
                    set_tabs={set_tabs}
                    post_id={itemdata?.post_id}
                    slug={slug}
                    router={router}
                    cache={cache}
                    set_cache={set_cache}
                    userdata={userdata}
                    ownerdata={ownerdata}
                    ssr_data_tribe={ssr_data_tribe}
                    descriptionRef={descriptionRef}
                    nodata={nodata}
                    navtype={navtype}
                    helptype={helptype}
                    comments={comments}
                    set_comments={set_comments}
                    likes={likes}
                    opens={opens}
                    isExpanded={true}
                    isFlat={false}
                    permaurl={permaurl}
                    origin_url={origin_url}
                    origin_title={origin_title}
                    clip_state={clip_state}
                    set_clip_state={set_clip_state}
                    openModalClip={openModal}
                    ssr_isadmin={ssr_isadmin_active}
                  />
                </div>

                <div className="block xs:hidden w-full text-center mt-4">
                  <button
                    onClick={() =>
                      modal_type == "post" ? closeModalPost() : closeModal()
                    }
                    className="cursor-pointer mx-auto border border-gray-700 hover:border-white hover:text-white text-gray-400 px-2 py-1 rounded-md"
                  >
                    <div className="flex items-center content-center">
                      <div className="mr-1">
                        <SvgJsx
                          type={"fill"}
                          icon={"x-circle-sm"}
                          className={`h-5 w-5  `}
                          title={"Close modal"}
                        />
                      </div>
                      <div>Close Modal</div>
                    </div>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* FOR NON-CLIPPING VIEW (0) OR ALREADY COMPLETED CLIPPING VIEW (2) */}
              {clip_state?.status !== 1 && (
                <PostFormContainer
                  modal_type={modal_type}
                  submitHandler={submitHandler}
                  submitting={submitting}
                  set_submitting={set_submitting}
                  processing={processing}
                  set_processing={set_processing}
                  set_changes={set_changes}
                  changes={changes}
                  max_files={config.post.max_files}
                  //post info
                  post_id={isOpen}
                  itemdata={itemdata}
                  itemdataisLoading={itemdataisLoading}
                  itemdataisError={itemdataisError}
                  ownerdata={ownerdata}
                  userdata={userdata}
                  show_graphic={false}
                  form_reset={form_reset}
                  set_form_reset={set_form_reset}
                  actionText={"Save changes"}
                  postcache={cache}
                  set_postcache={set_cache}
                  navtype={navtype}
                  helptype={helptype}
                  typevalue={typevalue}
                  set_typevalue={set_typevalue}
                  clip_state={clip_state}
                  clip_json={clip_json}
                />
              )}

              {/* ONLY SHOW FOR CLIPPING VIEW */}
              {clip_state?.status == 1 && (
                <>
                  <PostClipEdit
                    itemdata={itemdata}
                    duration={extractJson(
                      display_data?.post_contents_json,
                      "duration"
                    )}
                    audio_url={
                      !hasData(display_data?.post_contents_json)
                        ? null
                        : {
                            "2": JSON.parse(
                              display_data?.post_contents_json
                            ).filter((e) => hasData(e?.url))[0]?.url, //audio upload
                            "3": JSON.parse(
                              display_data?.post_contents_json
                            ).filter((e) => hasData(e?.url))[0]?.url, //mic recording
                            "5": JSON.parse(display_data?.post_contents_json)
                              .filter((e) => hasData(e?.url))[0]
                              ?.url?.replace(".mp3", ".mp4"), //show - get the mp4 version of show files, if we have it
                            "16": JSON.parse(
                              display_data?.post_contents_json
                            ).filter((e) => hasData(e?.url))[0]?.url, //sound upload
                          }[display_data?.post_type?.toString()] || null
                    }
                    waveform_url={
                      itemdata?.post_type?.toString() == "13"
                        ? hasData(display_data?.post_contents_json) &&
                          JSON.parse(display_data?.post_contents_json)
                            .filter((e) => hasData(e?.url))[0]
                            ?.url?.replace(".mp4", ".png")
                            ?.replace(".mp3", ".png")
                        : `/images/app/waveform-placeholder.png`
                    }
                    clip_state={clip_state}
                    set_clip_state={set_clip_state}
                    set_clip_json={set_clip_json}
                    changes={changes}
                    set_changes={set_changes}
                  />
                </>
              )}
            </>
          )
        }
        isOpen={isOpen}
        closeModal={modal_type == "post" ? closeModalPost : closeModal}
      />
    </>
  );
}
