import PostCommentLikesButton from "@/components/chat/post-comment-likes-button";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { idInJson } from "@/lib/utils/idInJson";
import { isBlocked } from "@/lib/utils/isBlocked";
import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import { useSession } from "next-auth/client";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import PostCommentOptions from "./post-comment-options";
import PostCommentsApproval from "./post-comments-approval";
import { likeHandlerComment } from "./post-handlers";
import { ifNull } from "@/lib/utils/ifNull";
import { markDownReact } from "@/lib/utils/markDown";

function PostCommentSingle({
  itemdata,
  profiledata,
  userdata,
  isAdmin,
  openModal,
  removeHandler,
  blockHandler,
  ignoreHandler,
  approvePostCommentHandler,
  handleReportAdmin,
  reportHandler,
  set_cache,
  set_comments,
  ssr_data_tribe,
  chat_emoji,
}) {
  const [session, loading] = useSession();
  const [likes, set_likes] = useState(itemdata?.likes || 0);

  const menuRef = useRef(null);
  const closeMenu = () => {
    if (menuRef.current) menuRef.current.close();
  };

  const [isPending, set_isPending] = useState(
    itemdata?.bol_delete?.toString() == "-1"
  );
  useEffect(() => {
    set_isPending(itemdata?.bol_delete?.toString() == "-1");
  }, [itemdata?.bol_delete]);

  return (
    <>
      <div
        className={`w-full py-4 
                                    border
                                    ${
                                      itemdata?.bol_delete?.toString() == "-1"
                                        ? "border-red-700"
                                        : "border-transparent"
                                    }
                                    `}
      >
        <div className="flex items-center content-center w-full  px-4">
          <div className="mr-2 flex-0 font-bold flex items-center content-center">
            <div>
              <Link href={`/${itemdata.user_name?.toLowerCase()}`}>
                <AuthorCredit
                  display_data={itemdata}
                  isPlaying={false}
                  profiledata={profiledata}
                  size={"tiny"}
                />
              </Link>
            </div>
            <div>{itemdata?.emoji?.toString() == "1" ? `` : ``}</div>
          </div>
          <div className="flex-1"></div>

          <div className="px-2">
            {/* <ChatTimer
                                                    start_date={+new Date(itemdata?.crdate)}
                                                    timestamp={+new Date(itemdata?.timestamp)}
                                                    time={time}
                                                /> */}
          </div>
        </div>
        <div className="px-4 flex items-top content-top mt-1.5 group">
          {itemdata?.emoji?.toString() == "1" && (
            <div className="flex-1 xs:ml-[32px] text-2xl">{chat_emoji}</div>
          )}
          {itemdata?.emoji?.toString() == "0" && (
            <div className="flex-1 xs:ml-[38px]">
              {markDownReact(
                ifNull(itemdata?.msg, ``),
                ssr_data_tribe?.server_website
              )}
            </div>
          )}

          <div className="flex flex-0 items-center content-center ">
            {/* LIKES */}
            <div
              className={`
                                                    ${
                                                      parseInt(likes) > 0
                                                        ? "block"
                                                        : "invisible group-hover:visible"
                                                    } flex-0 ml-2 mr-1 
                                                    `}
            >
              <PostCommentLikesButton
                likes_json={JSONpreparrayforurl(itemdata?.likes_json)}
                commenter_id={itemdata?.id}
                my_id={userdata?.id}
                onClick={async () => {
                  if (session) {
                    let action = await likeHandlerComment(
                      itemdata?.comment_id,
                      itemdata?.id,
                      userdata?.id,
                      profiledata?.id
                    );
                    set_likes((prev) => {
                      if (["1", "2"].indexOf(action?.toString()) > -1)
                        return prev + 1;
                      if (["-1"].indexOf(action?.toString()) > -1)
                        return prev - 1;
                      return prev;
                    });
                  }
                }}
                likes={likes}
                chat_emoji={chat_emoji}
                openModal={openModal}
                session={session}
                closeMenu={closeMenu}
              />
            </div>

            <div className="flex-0">
              <PostCommentOptions
                commenter_id={itemdata?.id}
                comment_id={itemdata?.comment_id}
                msg={itemdata?.message}
                commenter_name={itemdata?.user_name}
                clicker_name={userdata?.user_name}
                clicker_id={userdata?.id}
                owner_id={profiledata?.id}
                owner_name={profiledata?.user_name}
                removeHandler={removeHandler}
                blockHandler={blockHandler}
                ignoreHandler={ignoreHandler}
                approvePostCommentHandler={approvePostCommentHandler}
                handleReportAdmin={handleReportAdmin}
                reportHandler={reportHandler}
                isAdmin={isAdmin}
                isOwner={itemdata?.id == userdata?.id}
                isIgnored={idInJson(userdata?.ignore_json, itemdata?.id)}
                isApproved={idInJson(profiledata?.approve_json, itemdata?.id)}
                isBlocked={isBlocked(
                  profiledata?.block_json,
                  itemdata?.timestamp,
                  itemdata?.user_name
                )}
                openModal={openModal}
                likes={itemdata?.likes}
                likes_json={itemdata?.likes_json}
                reports={itemdata?.reports}
                reports_json={itemdata?.reports_json}
                crdate={new Date(itemdata?.crdate)?.toJSON()?.toString()}
                message={
                  <>
                    <span className="break-words text-ellipsis overflow-hidden">
                      {itemdata?.msg}
                    </span>
                  </>
                }
                message_original_raw={itemdata?.message_original_raw}
                emoji={itemdata?.emoji}
                bol_filtered={
                  userdata?.bol_ignore_filters?.toString() == "1"
                    ? "0"
                    : itemdata?.bol_filtered
                }
                set_cache={set_cache}
                set_comments={set_comments}
                ssr_data_tribe={ssr_data_tribe}
              />
            </div>
          </div>
        </div>

        {isPending && (
          <div className="w-full mt-5 ">
            <PostCommentsApproval
              isAdmin={isAdmin}
              comment_id={itemdata?.comment_id}
              set_cache={set_cache}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PostCommentSingle;
