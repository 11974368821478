import { toastConfig } from "react-simple-toasts";
import { getPrepEmail } from "../admin/getPrepEmail";

export async function reportHandler_admin_email({
  chat_id,
  chat_text,
  chatter_name,
  chatter_id,
  reporter_name,
  reporter_id,
  ssr_data_tribe,
}) {
  const chat_report = `Contents:
================================================
${chat_text} 
================================================

[chat by: ${chatter_name} / ${chatter_id}]

[reported by: ${reporter_name} / ${reporter_id}]`;

  try {
    await getPrepEmail({
      ssr_data_tribe: ssr_data_tribe,
      emailto: ssr_data_tribe?.email_support,
      emailtoname: "SUPPORT",
      bol_notification_bitarray: "1",
      notification_bitarray: 32767,
      notification_type: "change",
      emailfrom: ssr_data_tribe?.email_notifications,
      emailfromname: ssr_data_tribe?.brand_name,
      emailsubject: `Reporting Chat content - ID: ${chat_id}`,
      emailbodytext: chat_report,
      emailbodyhtml: chat_report, //optional
      emailccto: ssr_data_tribe.email_notifications, //include support to reply
      emailcctoname: ssr_data_tribe?.brand_name, //include support to reply
    });

    toastConfig({
      time: 10000,
      className: "",
      position: "right",
    });
  } catch (e) {
    //set_submitting(false)
    throw Error(e.message);
  }
}
