import ApiInstructionsCopy from "@/components/settings/user/user-broadcast/api-instructions-copy";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabIcon,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import toast from "react-simple-toasts";

const iconsize = 64;
const iconsize_small = 32;

export default function ShareSocialWrapper({
  url,
  image_url = "",
  title,
  description,
  hashtags,
  source,
}) {
  return (
    <>
      <div className="flex flex-col items-center content-center gap-x-2 w-full">
        <div className="w-full text-2xl font-bold mb-5 text-center">
          {title}
        </div>
        <div className="flex flex-wrap flex-1 items-center content-center gap-x-2 w-full justify-center">
          <div title="facebook">
            <FacebookShareButton url={url} hashtag={hashtags[0]}>
              <div className="hidden md:block">
                <FacebookIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <FacebookIcon size={iconsize_small} round={true} />
              </div>
            </FacebookShareButton>
          </div>
          <div title="twitter">
            <TwitterShareButton
              url={url}
              title={title}
              hashtags={hashtags}
              related={[`dialtribe`]}
            >
              <div className="hidden md:block">
                <TwitterIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <TwitterIcon size={iconsize_small} round={true} />
              </div>
            </TwitterShareButton>
          </div>
          <div title="whatsapp">
            <WhatsappShareButton url={url} title={title}>
              <div className="hidden md:block">
                <WhatsappIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <WhatsappIcon size={iconsize_small} round={true} />
              </div>
            </WhatsappShareButton>
          </div>
          <div title="telegram">
            <TelegramShareButton url={url} title={title}>
              <div className="hidden md:block">
                <TelegramIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <TelegramIcon size={iconsize_small} round={true} />
              </div>
            </TelegramShareButton>
          </div>
          <div title="reddit">
            <RedditShareButton url={url} title={title}>
              <div className="hidden md:block">
                <RedditIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <RedditIcon size={iconsize_small} round={true} />
              </div>
            </RedditShareButton>
          </div>
          <div title="pinterest">
            <PinterestShareButton
              url={url}
              description={description}
              media={image_url}
            >
              <div className="hidden md:block">
                <PinterestIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <PinterestIcon size={iconsize_small} round={true} />
              </div>
            </PinterestShareButton>
          </div>
          <div title="linkedin">
            <LinkedinShareButton
              url={url}
              title={title}
              summary={description}
              source={source}
            >
              <div className="hidden md:block">
                <LinkedinIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <LinkedinIcon size={iconsize_small} round={true} />
              </div>
            </LinkedinShareButton>
          </div>
          <div title="gab">
            <GabShareButton url={url} title={title}>
              <div className="hidden md:block">
                <GabIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <GabIcon size={iconsize_small} round={true} />
              </div>
            </GabShareButton>
          </div>
          <div title="email">
            <EmailShareButton subject={title} body={description} url={url}>
              <div className="hidden md:block">
                <EmailIcon size={iconsize} round={true} />
              </div>
              <div className="block md:hidden">
                <EmailIcon size={iconsize_small} round={true} />
              </div>
            </EmailShareButton>
          </div>
        </div>
        <div className="flex-1 w-full">
          <ApiInstructionsCopy
            copy_content={url}
            display_content={url}
            headline={`Copy URL`}
            toastFunction={() =>
              toast("Copied to clipboard", {
                time: 1000,
                className: "",
                clickable: true,
                clickClosable: false,
              })
            }
            containerHeightClass={""}
            showkey={null}
            set_showkey={() => {}}
            bol_showbutton={false}
            showbutton_text={""}
          />
        </div>
      </div>
    </>
  );
}
