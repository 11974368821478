import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { useRouter } from "next/router";

export default function PostMenuOptions({
  item_id,
  item_name,
  shrink = false,
  userdata,
  owner_id,
  timestamp,
  session,
  openModal,
  closeModal,
  cache,
  set_cache,
  permaurl,
  ssr_isadmin,
  helptype,
  isContributor,
  post_date,
  post_type,
  deleteHandler,
  stickyHandler,
  transcribeHandler,
  bol_transcribe,
  bol_replay_host,
  transcribe_status,
  bol_sticky,
  player_state,
  set_player_state,
  file,
  duration,
  content_id,
  content_table,
  owner_name,
  avatar_url,
  start_date,
  stream_status,
  clip_state,
  set_clip_state,
}) {
  const [isMounted, set_isMounted] = useState(false);

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const router = useRouter();

  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (ref.current) ref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const PopupTrigger = (
    <button
      aria-haspopup="true"
      tabIndex={0}
      className="menu-item w-5 h-5 flex-0 "
    >
      <SvgJsx
        type="outline"
        icon="dots-v"
        className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                          text-gray-400 hover:text-gray-200`}
        title="More"
      />
    </button>
  );

  return (
    <div className="menu flex items-center content-center  flex-0  ">
      {!isMounted && PopupTrigger}
      {isMounted && (
        <Popup
          trigger={PopupTrigger}
          position="left top"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{
            padding: "0px",
            border: "none",
            backgroundColor: "transparent",
          }}
          arrow={false}
          nested
          ref={isMounted ? ref : null}
        >
          <div
            className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                      bg-gray-800 "
          >
            <PopupMenuOption
              isDisplay={true}
              onClick={() => {
                closeMenu();
                router.push(permaurl);
              }}
              icon="link-sm"
              title="Permalink"
            />

            <PopupMenuOption
              isDisplay={
                player_state?.post_id?.toString() == item_id?.toString()
              }
              onClick={() => {
                closeMenu();
                router.push(`${permaurl}?t=${player_state?.seek}`);
              }}
              icon="link-sm"
              title="Current Timestamp"
            />

            <PopupMenuOption
              isDisplay={
                bol_transcribe?.toString() == "1" &&
                transcribe_status?.toString() == "1"
              }
              onClick={() => {
                closeMenu();
                router.push(`${permaurl}?show=transcript#tabs`);
              }}
              icon="document-text-sm"
              title={"View Transcript"}
            />

            <PopupMenuOption
              isDisplay={
                post_type?.toString() == "5" &&
                stream_status?.toString() == "0" &&
                bol_replay_host?.toString() == "1"
              }
              onClick={() => {
                closeMenu();
                router.push(`${permaurl}?show=replay#tabs`);
              }}
              icon="chat-alt-sm"
              title={"Replay Chat"}
            />

            {/* EDIT */}
            {ssr_isadmin[helptype] && (
              <>
                <PopupMenuOption
                  isDisplay={ssr_isadmin[helptype]}
                  onClick={() => {
                    closeMenu();
                    set_clip_state({
                      status: 0,
                      auto: 1,
                      start: 0,
                      track: 0,
                      end: 0,
                    });
                    openModal("form", item_id, post_type);
                  }}
                  icon="pencil-sm"
                  title="Edit"
                />

                <PopupMenuOption
                  isDisplay={
                    ssr_isadmin[helptype] && //admin/owner only
                    ((post_type?.toString() == "5" &&
                      stream_status?.toString() == "0") || //ended live broadcast
                      ["2", "3", "13"].indexOf(post_type?.toString()) > -1) //uploaded audio
                  }
                  onClick={() => {
                    closeMenu();
                    set_clip_state({
                      status: 1,
                      auto: 1,
                      start: 0,
                      track: 0,
                      end: 0,
                    });
                    set_player_state((prev) => {
                      return { ...prev, isPlaying: false };
                    });
                    openModal("clip", 0, 13);
                  }}
                  icon="scissors-sm"
                  title="Create a Clip"
                />

                <PopupMenuOption
                  isDisplay={post_type > 0}
                  onClick={() => {
                    closeMenu();
                    stickyHandler(item_id);
                  }}
                  icon="thumbtack-sm"
                  title={
                    bol_sticky?.toString() == "0"
                      ? "Sticky Post"
                      : "Unsticky Post"
                  }
                />

                <PopupMenuOption
                  isDisplay={
                    isContributor &&
                    ["2", "5", "13"].indexOf(post_type?.toString()) > -1 &&
                    bol_transcribe?.toString() == "1" &&
                    transcribe_status?.toString() == "-1"
                  }
                  onClick={() => {
                    closeMenu();
                    transcribeHandler(item_id);
                  }}
                  icon="beaker-sm"
                  title={"Transcribe Audio"}
                />
                <PopupMenuOption
                  isDisplay={true}
                  onClick={() => {
                    closeMenu();
                    set_clip_state({
                      status: 0,
                      auto: 1,
                      start: 0,
                      track: 0,
                      end: 0,
                    });
                    openModal("share", item_id, post_type);
                  }}
                  icon="share-sm"
                  title="Share Files"
                />
                <PopupMenuOption
                  isDisplay={post_type > 0 && post_type?.toString() !== "14"}
                  onClick={() => {
                    closeMenu();
                    set_clip_state({
                      status: 0,
                      auto: 1,
                      start: 0,
                      track: 0,
                      end: 0,
                    });
                    openModal("download", item_id, post_type);
                  }}
                  icon="document-download-sm"
                  title="Download Files"
                />
                <PopupMenuOption
                  isDisplay={ssr_isadmin[helptype]}
                  onClick={() => {
                    closeMenu();
                    deleteHandler(item_id);
                  }}
                  icon="trash-sm"
                  title="Delete"
                />

                <div className="text-center text-xs mt-1 italic">
                  {post_date}
                </div>
              </>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
}
