import { useContext, useEffect, useRef } from "react";
import { PlayerContext } from "@/components/context/player-context";
import { clockToSeconds } from "./clockToSeconds";
import { useUserAuth } from "./useUserAuth";
import { startPlayerTimestamp } from "../startPlayerTimestamp";

export default function useTimestamp(posts_data) {
  const descriptionRef = useRef(null);
  const { player_state, set_player_state } = useContext(PlayerContext);
  const { userdata } = useUserAuth();

  useEffect(() => {
    descriptionRef?.current?.addEventListener(
      "mousedown",
      evalTimestamp(posts_data)
    );

    return () =>
      descriptionRef?.current?.removeEventListener(
        "mousedown",
        evalTimestamp(posts_data)
      );
  }, [descriptionRef?.current]);

  const evalTimestamp = (posts_data) => {
    document.body.onmousedown = function (e) {
      var elementId = e?.target;

      if (
        (elementId as HTMLElement)
          ?.getAttribute("data-timestamp")
          ?.toString() == "1"
      ) {
        //console.log("contact",(elementId as HTMLElement)?.innerHTML)
        let timestamp = (elementId as HTMLElement)?.innerHTML;
        let index = parseInt(
          (elementId as HTMLElement)?.getAttribute("data-timestampindex")
        );
        let stamp = clockToSeconds(timestamp);

        //this function uses the post_id as a key index to make sure we clicked on the right item (stored on the data-timestampindex attribute in the html)
        //we then filter out all the posts_data to just focus on the correct post

        var found_index = posts_data?.findIndex((p) => p?.post_id == index);
        let posts_data_single = posts_data[found_index];

        if (found_index == -1) {
          alert("Something went wrong.");
        }

        startPlayerTimestamp(
          stamp,
          player_state,
          set_player_state,
          userdata,
          posts_data_single
        );
      }
    };
  };

  return { descriptionRef };
}
