import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { Fragment, useRef, useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { blockactions } from "@/lib/config";
import Link from "next/link";
//import PopupMenuOptionLink from "@/components/templateux/popup-menu-option-link";

import AuthContext from "@/components/auth/context";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";

export default function PostCommentOptions({
  commenter_id,
  comment_id,
  msg,
  commenter_name,
  clicker_name,
  clicker_id,
  owner_id,
  owner_name,
  removeHandler,
  blockHandler,
  ignoreHandler,
  approvePostCommentHandler,
  handleReportAdmin,
  reportHandler,
  isAdmin,
  isOwner,
  isBlocked,
  isIgnored,
  isApproved,
  openModal,
  likes,
  likes_json,
  reports,
  reports_json,
  crdate,
  message,
  message_original_raw,
  emoji,
  bol_filtered,
  set_cache,
  set_comments,
  ssr_data_tribe,
}) {
  const authContext = React.useContext(AuthContext);
  const vcrdate = new Date(crdate);

  const { isConfirmed } = useConfirm();

  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (ref.current) ref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const did_i_report = reports > 0;

  return (
    <div className="menu flex items-center content-center  flex-0  ">
      <Popup
        trigger={
          <div aria-haspopup="true" className="menu-item w-5 h-5 flex-0 ">
            <SvgJsx
              type="outline"
              icon="dots-v"
              className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5

                                ${
                                  bol_filtered?.toString() == "1"
                                    ? `
                                text-red-400 hover:text-red-200
                                  `
                                    : `
                                text-gray-400 hover:text-gray-200
                                  `
                                }

                              `}
              title="More"
            />
          </div>
        }
        position="left top"
        on="click"
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{
          padding: "0px",
          border: "none",
          backgroundColor: "transparent",
        }}
        arrow={false}
        nested
        ref={ref}
      >
        <div
          className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                    bg-gray-800 "
        >
          {/* VIEW PROFILE */}
          <PopupMenuOption
            isDisplay={true}
            onClick={() => {
              closeMenu();
              openModal(
                commenter_name,
                "single",
                "users",
                1,
                "Individual User"
              );
            }}
            icon="profile-sm"
            title="Profile Menu"
          />

          {/* View Likes*/}
          <PopupMenuOption
            isDisplay={likes > 0}
            onClick={() => {
              closeMenu();
              openModal(likes_json, "users", "likes", likes);
            }}
            icon="heart-sm"
            title="See Likes"
          />

          {/* REPORT NEW */}
          <PopupMenuOption
            isDisplay={!isOwner}
            onClick={() => {
              closeMenu();
              reportHandler(
                comment_id,
                clicker_id,
                owner_id,
                !did_i_report ? 1 : -1
              );
              handleReportAdmin(
                comment_id,
                msg,
                commenter_name,
                commenter_id,
                clicker_name,
                clicker_id,
                ssr_data_tribe
              );
            }}
            icon="flag-sm"
            title={!did_i_report ? `Report message` : `Remove report`}
          />

          {/* DELETE */}
          <PopupMenuOption
            isDisplay={isAdmin || clicker_id == commenter_id}
            onClick={() => {
              closeMenu();
              removeHandler(
                comment_id,
                owner_id,
                set_cache,
                isConfirmed,
                set_comments
              );
            }}
            icon="trash-sm"
            title="Delete message"
          />

          {/* APPROVE */}
          <PopupMenuOption
            isDisplay={isAdmin} //&& (userdata?.id == owner_id)) }
            onClick={() => {
              closeMenu();
              approvePostCommentHandler(
                commenter_id,
                isApproved ? "0" : "1",
                commenter_name,
                authContext,
                isConfirmed
              );
            }}
            icon="eye-off-sm"
            title={isApproved ? "Un-Approve" : "Approve"}
          />

          {/* IGNORE */}
          <PopupMenuOption
            isDisplay={clicker_id !== commenter_id} //&& (userdata?.id == owner_id)) }
            onClick={() => {
              closeMenu();
              ignoreHandler(
                commenter_id,
                isIgnored ? "0" : "1",
                commenter_name,
                authContext,
                isConfirmed
              );
            }}
            icon="eye-off-sm"
            title={isIgnored ? "Un-Ignore" : "Ignore"}
          />

          {/* UNBLOCK */}

          <PopupMenuOption
            isDisplay={isAdmin && clicker_id !== commenter_id && isBlocked}
            onClick={() => {
              closeMenu();
              blockHandler(
                owner_id,
                commenter_id,
                commenter_name,
                -1,
                isConfirmed
              );
            }}
            icon="x-circle-sm"
            title={"Unblock"}
          />

          {isAdmin && (
            <>
              <Popup
                trigger={
                  <div aria-haspopup="true" className="menu-item ">
                    <PopupMenuOption
                      isDisplay={
                        isAdmin &&
                        clicker_id?.toString() !== commenter_id?.toString() &&
                        commenter_id?.toString() !== owner_id?.toString() &&
                        !isBlocked
                      } //&& (userdata?.id == owner_id)) }
                      icon="scale-sm"
                      title={"User Moderation"}
                      onClick={() => {}}
                    />
                  </div>
                }
                position="left center"
                on="click"
                closeOnDocumentClick
                closeOnEscape
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                arrow={false}
                nested
                ref={ref}
              >
                <div
                  className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                        bg-gray-800 "
                >
                  {/* BLOCK */}
                  {blockactions
                    .filter(
                      (e) =>
                        ["0", "1", "2", "3", "4", "5", "6"].indexOf(
                          e.action.toString()
                        ) >= 0
                    )
                    .map((e, index) => (
                      <Fragment key={index}>
                        <PopupMenuOption
                          isDisplay={
                            isAdmin &&
                            clicker_id?.toString() !==
                              commenter_id?.toString() &&
                            commenter_id?.toString() !== owner_id?.toString() &&
                            !isBlocked
                          }
                          onClick={() => {
                            closeMenu();
                            blockHandler(
                              owner_id,
                              commenter_id,
                              commenter_name,
                              e.action,
                              isConfirmed
                            );
                          }}
                          icon="x-circle-sm"
                          title={e.button_msg}
                        />
                      </Fragment>
                    ))}
                </div>
              </Popup>
            </>
          )}

          {emoji?.toString() == "0" && ( //real text post
            <>
              <Popup
                trigger={
                  <div aria-haspopup="true" className="menu-item ">
                    <PopupMenuOption
                      isDisplay={true} //&& (userdata?.id == owner_id)) }
                      icon="x-circle-sm"
                      onClick={() => {}}
                      title={
                        <div
                          className={`
                                  ${
                                    bol_filtered?.toString() == "1"
                                      ? `
                                  text-red-400 hover:text-red-200
                                    `
                                      : `
                                    `
                                  }
                                  `}
                        >
                          View raw message
                        </div>
                      }
                    />
                  </div>
                }
                position="left center"
                on="click"
                closeOnDocumentClick
                closeOnEscape
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                arrow={false}
                nested
                ref={ref}
              >
                <div
                  className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                        bg-gray-800 "
                >
                  <textarea className="overflow-scroll text-black text-xs">
                    {message_original_raw}
                  </textarea>
                </div>
              </Popup>
            </>
          )}

          <div className="text-sm text-center mt-2">
            <div>
              <Link
                href={`/${commenter_name?.toLowerCase()}`}
                className="underline hover:no-underline"
              >
                {commenter_name}
              </Link>
            </div>
            <div
              className={`break-all
                                  ${
                                    bol_filtered?.toString() == "1"
                                      ? `
                                  text-red-200 
                                    `
                                      : `
                                    `
                                  }
                                  `}
            >
              {message}
            </div>
            <div>
              Comment ID:
              <a
                className="underline hover:no-underline ml-1"
                href={`#${comment_id}`}
              >
                #{comment_id}
              </a>
            </div>
            <div>{vcrdate.toLocaleString()}</div>
          </div>
        </div>
      </Popup>
    </div>
  );
}
