import { Message } from "@/components/chat/Message";
import { TribeContext } from "@/components/context/tribe-context";
import { PlayerContext } from "@/components/context/player-context";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import { config } from "@/lib/config";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import pageint from "@/lib/utils/pageint";
import { useSession } from "next-auth/client";
import { useContext, useEffect, useRef, useState } from "react";

function PostKudos({ itemdata, profiledata, router, userdata, ssr_isadmin }) {
  const ssr_data_tribe = useContext(TribeContext);
  const { player_state, set_player_state } = useContext(PlayerContext);
  const page = pageint(router.query.page);
  const inputRef = useRef();
  const [session] = useSession();
  const [chatdata, set_chatdata] = useState<any[] | undefined>([]); //ssr_data_chat?.reverse()?.slice(75)
  const [filtercache, set_filtercache] = useState(+new Date());
  const csort = router.query.sort || config.itemsort;
  const cdir = router.query.dir || config.itemdir;

  // INITIAL MSG DATA
  const {
    data: chatarraydata,
    loading: chatarraydataisLoading,
    error: chatarraydataisError,
    boundMutate: itemsMutate,
  } = useSWRWrapper(
    itemdata?.user_id?.toString(), //id
    `/api/private/chat/select?id=${itemdata?.user_name}&filter=kudospost&bid=${itemdata?.content_id}&cache=${filtercache}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //config.chat.messages_interval //refreshinterval (0=none)
  );

  useEffect(() => {
    if (
      !chatarraydataisLoading &&
      !chatarraydataisError &&
      chatarraydata &&
      userdata
    ) {
      //add a check for whether there is ignored user content here XXX........
      let this_ignore_json = userdata?.ignore_json;
      if (this_ignore_json?.length > 0 && this_ignore_json !== "[]") {
        const ignore_filter = JSON.parse(userdata?.ignore_json);
        //run a filter comparison against all messages and stripped out any ignoreed users
        set_chatdata(
          chatarraydata
            ?.filter(({ id: id1, target_id: ti1 }) =>
              ignore_filter.some(({ id: id2 }) => id2 !== id1 && id2 !== ti1)
            )
            ?.reverse()
        );
      } else {
        set_chatdata(chatarraydata?.reverse());
      }
    }
  }, [chatarraydataisLoading, chatarraydataisError, chatarraydata, userdata]);

  return (
    <>
      {/* TOP ROW */}
      <div className="flex w-full flex-col items-center content-center text-left divide-y divide-gray-700">
        {chatarraydataisLoading && chatdata?.length == 0 && (
          <div className="p-10">
            <LoadingProcessing />
          </div>
        )}

        {!chatarraydataisLoading && chatdata?.length == 0 && (
          <div className="p-10">No kudos awarded.</div>
        )}

        {/* CHAT IS POPULATED */}
        {!chatarraydataisLoading &&
          chatdata?.length > 0 &&
          chatdata?.map((msg_object, index) => {
            return (
              <div className="w-full" key={msg_object?.chat_id}>
                <Message
                  type={msg_object?.type}
                  index={index}
                  chat={msg_object}
                  profiledata={profiledata}
                  userdata={userdata}
                  ssr_isadmin={ssr_isadmin}
                  helptype={"chat"}
                  show_header={true}
                  show_footer={true}
                  openModal={() => {}}
                  openModalChooser={() => {}}
                  closeModal={() => {}}
                  msg={{}}
                  set_msg={() => {}}
                  session={session}
                  chat_emoji={itemdata?.chat_emoji}
                  inputRef={inputRef}
                  position={"left center"}
                  time={0}
                  chatWidth={"100%"}
                  set_commenting={() => {}}
                  server_website={ssr_data_tribe?.server_website?.toString()}
                  display_actions={false}
                  params={null}
                  origin={"post"}
                  router={router}
                  player_state={player_state}
                  set_player_state={set_player_state}
                  itemdata={itemdata}
                  ssr_data_tribe={ssr_data_tribe}
                  highlight={0}
                  set_highlight={() => {}}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}

export default PostKudos;
