import { hasData } from "@/lib/utils/hasData";
import { textProcess } from "@/lib/utils/textprocess";
import Link from "next/link";

{
  /* POST Tags */
}
export default function tags({ tags, url = "/?search=" }) {
  return (
    <>
      {hasData(tags) && (
        <div className="text-left  inline">
          {tags.split(",")?.map((tag, index) => {
            return (
              <div className="inline-flex" key={index}>
                <Link
                  href={`${url}${encodeURIComponent(
                    tag?.trim()?.replace(/\#/gi, "")
                  )}`}
                  className="mr-2 underline hover:no-underline cursor-pointer italic text-gray-400"
                >
                  #{textProcess(tag)?.trim()?.replace(/\#/gi, "")}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
