import { PlayerContext } from "@/components/context/player-context";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { config } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { startPlayerTimestamp } from "@/lib/utils/startPlayerTimestamp";
import { textProcess } from "@/lib/utils/textprocess";
import { timerFormatSeconds } from "@/lib/utils/timerFormat";
import Link from "next/link";
import { useContext, useEffect, useState } from "react";

export default function PostTimestamp({
  itemdata,
  profiledata,
  router,
  userdata,
  openModal,
  set_clip_state,
}) {
  const [isMounted, set_isMounted] = useState(false);
  useEffect(() => {
    set_isMounted(true);
  }, []);

  const { player_state, set_player_state } = useContext(PlayerContext);

  const [cache, set_cache] = useState("");

  // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})

  //SWR to retrieve all chat messages here
  const {
    data: timestamparraydata,
    loading: timestamparraydataisLoading,
    error: timestamparraydataisError,
    boundMutate: itemsMutate,
  } = useSWRWrapper(
    itemdata?.post_id?.toString(), //id
    `/api/private/timestamp/select?id=${itemdata?.post_id}&cache=${cache}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    0 //refreshinterval (0=none)
  );

  // {JSON.stringify(timestamparraydata)}

  if (timestamparraydataisLoading) {
    return (
      <>
        {" "}
        <div className="p-10">
          <LoadingProcessing />
        </div>
      </>
    );
  }

  if (!timestamparraydataisLoading) {
    return (
      <>
        <div className="">
          {timestamparraydata.length == 0 && (
            <div className="text-center py-10 italic text-gray-200">
              {textProcess(`No timestamps to show.`)}
            </div>
          )}
          {timestamparraydata.length > 0 &&
            timestamparraydata?.map((ts, index) => {
              return (
                <div
                  className="font-normal flex items-top content-start px-4"
                  key={index}
                >
                  <div
                    className={`flex-1 text-left py-4 ${
                      index > 0 ? "border-t border-gray-700" : ""
                    }`}
                  >
                    <div key={index} className="w-full ">
                      <div className="flex items-center content-center w-full px-4 ">
                        <div className="mr-2 flex-0 font-bold">
                          <Link href={`/${ts.user_name?.toLowerCase()}`}>
                            <AuthorCredit
                              display_data={ts}
                              isPlaying={false}
                              profiledata={profiledata}
                              size={"tiny"}
                            />
                          </Link>
                        </div>

                        <div className="flex-1"></div>
                        <div className="pt-3 flex items-center content-center">
                          <button
                            className={`pl-2 text-blue-400 underline hover:no-underline cursor-pointer  `}
                            onClick={() => {
                              startPlayerTimestamp(
                                ts.duration,
                                player_state,
                                set_player_state,
                                userdata,
                                itemdata
                              );
                            }}
                          >
                            {timerFormatSeconds(Math.round(ts.duration))}
                          </button>
                          <button
                            onClick={() => {
                              set_clip_state({
                                status: 1,
                                auto: 0,
                                start: Math.round(ts.duration) - 120,
                                track: Math.round(ts.duration) - 60,
                                end: Math.round(ts.duration) + 1,
                              });

                              set_player_state((prev) => {
                                return { ...prev, isPlaying: false };
                              });
                              openModal("clip", 0, 13);
                            }}
                            className={`ml-2 px-2 py-1 text-white border rounded-md border-gray-700 hover:border-white cursor-pointer `}
                          >
                            Clip
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className=" ml-5 ">
                      {hasData(ts?.tag_json) && (
                        <>
                          {JSON.parse(ts?.tag_json)?.map((e, index) => {
                            return (
                              <div
                                key={index}
                                className="flex-0 inline text-2xs bg-gray-600 text-white px-1 py-0.5 rounded-md mr-2"
                              >
                                {e?.t}
                              </div>
                            );
                          })}
                        </>
                      )}
                      {hasData(ts.notes) && (
                        <div className="flex-1 inline">
                          {textProcess(ts.notes)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  }
}
